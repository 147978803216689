import {Engine} from "platform/engine/Engine";
import {
    ClearServerErrors,
    DoSocialLoginPayload,
    DoSubmitChangePasswordFormPayload,
    DoSubmitForgotPasswordFormPayload,
    DoSubmitLoginForm,
    DoSubmitLoginFormPayload,
    DoSubmitLoginTokenPayload,
    DoSubmitResetPasswordFormPayload,
    DoSubmitSignUpFormPayload,
    DoVerifyResetPasswordPayload,
    SetBoardForm,
    SetBoardFormSubmitting,
    SetChangePasswordRequired,
    SetPasswordReset,
    SetResetLinkSendToEmail,
    SetServerError,
    SetSignUpCountries,
    SetSignUpSource,
    SetTCLoginAgreement,
    SetVerificationCodeIssued,
    SetVerificationIsLimitReached,
    SetVerificationMethod,
    SetVerificationMethods,
    VerificationMethodPayload
} from "kbd/core/redux/board/BoardReduxActions";
import Platform from "platform/Platform";
import {StoreState} from "kbd/core/redux/StoreState";
import {HideLoader, NavigateTo, SetLoader} from "platform/redux/core/CoreActions";
import {UrlType} from "platform/enum/UrlType";
import WebUtil from "platform/util/WebUtil";
import {TSMap} from "typescript-map";
import {BoardFormType} from "kbd/enum/BoardFormType";
import {LoaderType} from "platform/enum/LoaderType";
import {FieldType} from "kbd/enum/FieldType";
import {ErrorCode} from "kbd/enum/ErrorCode";
import Utils from "platform/util/Utils";
import {HttpReject} from "platform/network/http/Http";
import {Configuration, OnBoardProductConfig} from "kbd/core/configuration/Configuration";
import {LoginRequest} from "kbd/protocol/auth/LoginRequest";
import {LoginResponse} from "kbd/protocol/auth/LoginResponse";
import {ForgotPasswordRequest} from "kbd/protocol/auth/ForgotPasswordRequest";
import {ForgotPasswordResponse} from "kbd/protocol/auth/ForgotPasswordResponse";
import {FormMethod as RedirectFormMethod} from "platform/enum/FormMethod";
import SubmitForm from "platform/network/form/SubmitForm";
import {XhrManager} from "kbd/core/engine/XhrManager";
import {TokenManager} from "kbd/core/util/TokenManager";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {DpkType} from "kbd/enum/DpkType";
import {ChangeExpiredPasswordRequest} from "kbd/protocol/auth/ChangeExpiredPasswordRequest";
import {ChangeExpiredPasswordResponse} from "kbd/protocol/auth/ChangeExpiredPasswordResponse";
import {SetModal} from "kbd/core/redux/app/AppReduxActions";
import {ModalType} from "kbd/enum/ModalType";
import {LoginGetTermAndConditionsRequest} from "kbd/protocol/auth/LoginGetTermAndConditionsRequest";
import {LoginGetTermAndConditionsResponse} from "kbd/protocol/auth/LoginGetTermAndConditionsResponse";
import {LoginNotifyAgreeTCRequest} from "kbd/protocol/auth/LoginNotifyAgreeTCRequest";
import {LoginNotifyAgreeTCResponse} from "kbd/protocol/auth/LoginNotifyAgreeTCResponse";
import {Route} from "platform/redux/PlatformReduxState";
import {PageType} from "kbd/enum/PageType";
import {BoardState} from "kbd/core/state/BoardState";
import {ServiceType} from "kbd/enum/ServiceType";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import Parameter from "platform/util/Parameter";
import {TranslationParam} from "kbd/enum/TranslationParam";
import {SignUpFormState} from "kbd/core/redux/board/BoardReduxState";
import {TokenResponse} from "kbd/protocol/auth/TokenResponse";
import {VerifyResetPasswordCodeRequest} from "kbd/protocol/auth/VerifyResetPasswordCodeRequest";
import {VerifyResetPasswordCodeResponse} from "kbd/protocol/auth/VerifyResetPasswordCodeResponse";
import {ResetPasswordAndLoginRequest} from "kbd/protocol/auth/ResetPasswordAndLoginRequest";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {GetResetPasswordVerificationCodeRequest} from "kbd/protocol/auth/GetResetPasswordVerificationCodeRequest";
import {GetResetPasswordVerificationCodeResponse} from "kbd/protocol/auth/GetResetPasswordVerificationCodeResponse";
import {MigrationDialogType} from "kbd/enum/MigrationDialogType";
import {DateTimeFormat} from "kbd/core/format/DateTimeFormat";
import {BIEventType} from "kbd/enum/BIEventType";
import {RegisterActivityRequest} from "kbd/protocol/request/RegisterActivityRequest";
import {ActivityTypeInfo} from "kbd/protocol/ActivityTypeInfo";
import {Xhr} from "platform/network/xhr/Xhr";
import {ServerType} from "platform/enum/ServerType";
import {BILoginType} from "kbd/enum/BILoginType";
import {BILoginTarget} from "kbd/enum/BILoginTarget";
import {BIUtil} from "kbd/core/util/BIUtil";
import {ConfigUtil} from "kbd/core/util/ConfigUtil";
import {ContactInfoSource} from "signup/enum/ContactInfoSource";
import {TBSignUp} from "signup/entry/TBSignUp";
import {SignUpResult} from "signup/core/type/SignUpResult";
import {SignUpMetaCommon} from "signup/core/type/SignUpMetaCommon";
import {SocialMediaType} from "signup/enum/SocialMediaType";
import {SignUpCountry} from "signup/core/type/SignUpCountry";
import {SignUpMetaSocial} from "signup/core/type/SignUpMetaSocial";
import {FirebaseAuth} from "signup/core/firebase/FirebaseAuth";
import {ProductType} from "kbd/entry/ProductType";
import {BrandType} from "platform/enum/BrandType";

const HashString: string = window.location.hash;
const QueryStringRaw: string = window.location.search;
const QueryString: string = QueryStringRaw.substring(1);
const AppleIdToken: string = WebUtil.urlParam("id_token");
const SocialParam: string = WebUtil.urlParam("social");
const DpkParam: string = WebUtil.urlParam("dpk");
const Dpk: DpkType = DpkType.deserialize(DpkParam);
const isTnCSigningRequired: boolean = Utils.parseBoolean(WebUtil.urlParam("isTnCSigningRequired"));
const TokenTC: string = WebUtil.urlParam("token") || WebUtil.urlParam("tokenId");
BoardState.instance().urlError = WebUtil.urlParam("Error")

export default class BoardEngine extends Engine {

    private static _instance: BoardEngine;
    private _tbSignUp: TBSignUp = new TBSignUp();

    public static instance(): BoardEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        await TokenManager.token();
        if (isTnCSigningRequired) {
            this._logger.debug("Required to accept TC");
            await this.doGetTermAndConditions(null, null, TokenTC);
        }
        // NOTE: scenario navigating back from social website to the sign-up/login pages
        if (SocialParam) {
            const socialSource: ContactInfoSource = ContactInfoSource.deserialize(SocialParam);
            this._logger.info(`Returned back from social sign in: ${socialSource}`);
            if (socialSource) {
                if (socialSource === ContactInfoSource.Apple) {
                    if (window.location.pathname === "/sign-up") {
                        Platform.dispatch(SetSignUpSource({signUpSource: socialSource}));
                        BoardState.instance().signUpSocialToken = AppleIdToken;
                    } else {
                        this.doLoginWithSocialToken(socialSource, AppleIdToken);
                    }
                } else {
                    const socialParams: string = HashString?.replace("#", "?");
                    if (socialParams) {
                        const accessToken: string = WebUtil.urlParam("access_token", socialParams);
                        if (accessToken) {
                            Platform.dispatch(SetSignUpSource({signUpSource: socialSource}));
                            BoardState.instance().signUpSocialToken = accessToken;
                        }
                    }
                }
            }
        }
    }

    public onChangeRoute = (payload): void => {
        const route: Route = payload.route;
        const pageType: PageType = route.name as PageType;
        const {countries} = Platform.store<StoreState>().getState().board;
        if (pageType === PageType.SignUp && Utils.isArrayEmpty(countries)) {
            const {brandId} = Platform.config<Configuration>();
            this._tbSignUp.SignUpCountries(brandId).then((signUpCountries: SignUpCountry[]) => {
                if (Utils.isArrayNotEmpty(signUpCountries)) {
                    const boardState: BoardState = Platform.state(ServiceType.Board);
                    Platform.dispatch(SetSignUpCountries({
                        countries: signUpCountries.map((country: SignUpCountry) => {
                            const ci: CountryInfo = {
                                Code: country.code,
                                PhoneCode: country.phoneCode,
                                LocalizedName: country.name,
                                HideForBirthCountry: false,
                                Id: null
                            };
                            boardState.addCountry(ci);
                            return ci;
                        })
                    }));
                }
            }).catch(() => {
                this._logger.warn("Failed fetch brand props");
            });
        }
    }

    public doSocialLogin = (payload: DoSocialLoginPayload): void => {
        const {socialSource, formType} = payload;
        this._logger.debug(`Do social login with: ${socialSource}, form: ${formType}`);
        Platform.dispatch(ClearServerErrors({}));
        if (formType === BoardFormType.SignUp) {
            this.doGetSocialToken(socialSource, formType, (token: string) => {
                Platform.dispatch(SetSignUpSource({signUpSource: socialSource}));
                BoardState.instance().signUpSocialToken = token;
            });
        } else {
            if (socialSource === ContactInfoSource.Apple) {
                this.doGetSocialToken(socialSource, formType, (token: string) => {
                    setTimeout(() => this.doLoginWithSocialToken(socialSource, token), 0);
                });
            } else {
                const url: string = this.getSocialLoginUrl(socialSource);
                if (url) {
                    const {brand, version} = Platform.config();
                    Platform.bi().track(BIEventType.LoginAttempt, {
                        Brand: brand,
                        LoginType: BILoginType.Social,
                        SocialLogin: socialSource,
                        Platform: BIUtil.Platform()
                    });
                    const {migrationDialogType} = Platform.reduxState<StoreState>().board;
                    if (migrationDialogType === MigrationDialogType.Fork) {
                        Platform.dispatch(SetModal({
                            modalType: ModalType.SoftLaunch,
                            visible: true,
                            parameters: [
                                Parameter.Of("Profit", () => {
                                    Platform.environment().redirect(WebUtil.addGetParam(url, "ExtraParameters", encodeURIComponent(
                                        JSON.stringify({TradingPlatform: 0, applicationVersion: `${brand} ${version}`})
                                    )));
                                }),
                                Parameter.Of("XCite", () => {
                                    Platform.environment().redirect(WebUtil.addGetParam(url, "ExtraParameters", encodeURIComponent(
                                        JSON.stringify({TradingPlatform: 1, applicationVersion: `${brand} ${version}`})
                                    )));
                                })
                            ]
                        }));
                    } else {
                        Platform.environment().redirect(WebUtil.addGetParam(url, "ExtraParameters", encodeURIComponent(
                            JSON.stringify({applicationVersion: `${brand} ${version}`})
                        )));
                    }
                } else {
                    this._logger.warn("Can't open empty social login url with type: " + socialSource);
                }
            }
        }
    }

    private doGetSocialToken = (socialSource: ContactInfoSource, formType: BoardFormType, onSuccess: (token: string) => void): void => {
        const isSignUp: boolean = formType === BoardFormType.SignUp;
        if (WebUtil.isMobile()) {
            const path: string = isSignUp ? "sign-up" : "login";
            const redirectURL: string = encodeURI(`${window.location.protocol}//${window.location.hostname}/${path}?social=${socialSource.toLowerCase()}`);
            const socialMeta: SignUpMetaSocial = {
                brandId: Platform.config<Configuration>().brandId,
                langCode: LanguageUtil.languageCode(),
                socialType: SocialMediaType.From(socialSource),
                redirectURL
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            if (socialSource === ContactInfoSource.Apple) {
                this._tbSignUp.SocialToken(socialMeta).catch(() => {
                    Platform.dispatch(SetServerError({
                        fieldType: isSignUp ? FieldType.SignUpPassword : FieldType.LoginPassword,
                        error: {
                            errorCode: ErrorCode.SocialError,
                            params: [Parameter.Of(TranslationParam.value, Utils.capitalize(SocialMediaType.From(socialSource)))]
                        }
                    }));
                }).finally(() => {
                    Platform.dispatch(HideLoader({}));
                });
            } else {
                this._tbSignUp.SocialUrl(socialMeta).then((url: string) => {
                    if (url) {
                        Platform.environment().redirect(url);
                    } else {
                        this._logger.warn("Can't redirect to the empty social url with type: " + socialSource);
                    }
                }).finally(() => {
                    Platform.dispatch(HideLoader({}));
                });
            }
        } else {
            const socialMeta: SignUpMetaSocial = {
                brandId: Platform.config<Configuration>().brandId,
                langCode: LanguageUtil.languageCode(),
                socialType: SocialMediaType.From(socialSource)
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            this._tbSignUp.SocialToken(socialMeta).then(onSuccess).catch(() => {
                this._logger.debug(`Failed get social token with: ${socialSource}`);
                Platform.dispatch(SetServerError({
                    fieldType: isSignUp ? FieldType.SignUpPassword : FieldType.LoginPassword,
                    error: {
                        errorCode: ErrorCode.SocialError,
                        params: [Parameter.Of(TranslationParam.value, Utils.capitalize(SocialMediaType.From(socialSource)))]
                    }
                }));
            }).finally(() => {
                Platform.dispatch(HideLoader({}));
            });
        }
    }

    private doLoginWithSocialToken = (socialSource: ContactInfoSource, jwtToken: string): void => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const {brandId, brand, version} = Platform.config();
        this._tbSignUp.SocialLogin({
            brandId,
            langCode: LanguageUtil.languageCode(),
            socialType: SocialMediaType.From(socialSource),
            token: jwtToken
        })
            .then((ssoToken: string) => {
                setTimeout(async () => {
                    const loginRequest: LoginRequest = {
                        Token: ssoToken,
                        BrandId: brandId,
                        LanguageCode: LanguageUtil.languageCode(),
                        ApplicationVersion: `${brand} ${version}`
                    };
                    await this.doLoginInternal(loginRequest);
                }, 0);
            })
            .catch((error) => {
                this._logger.warn("Social signIn error with code:", error?.error_code, ", status: ", error?.status);
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.LoginPassword,
                    error: {errorCode: ErrorCode.GeneralError}
                }));
            })
            .finally(() => {
                Platform.dispatch(HideLoader({}));
            });
    }

    private getSocialLoginUrl = (socialSource: ContactInfoSource): string => {
        const urls: TSMap<UrlType, string> = Platform.reduxState<StoreState>().core.urls;
        let url: string = urls.get(socialSource === ContactInfoSource.Google ? UrlType.GoogleLogin : UrlType.FacebookLogin);
        const params: { [key: string]: string } = WebUtil.getParamsToObject(url);
        if (params.loginuri) {
            params.loginuri = ConfigUtil.UseAddressBarDomain(params.loginuri);
            const {useAddressBarDomain, ignoreAddressBarSubDomains} = Platform.config<Configuration>();
            if (useAddressBarDomain) {
                const parts: string[] = window.location.hostname?.split(".");
                const locationSubDomain: string = parts[parts.length - 2];
                if (ignoreAddressBarSubDomains.indexOf(locationSubDomain) < 0) {
                    params.usedomain = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
                }
            }
            if (Utils.isNotEmpty(DpkParam)) {
                params.dpk = encodeURIComponent(QueryString);
                params.loginuri = WebUtil.addGetParam(params.loginuri, "dpk", DpkParam);
            }
        }
        if (params.reguri?.indexOf("qaplexop") < 0) {
            params.reguri = ConfigUtil.UseAddressBarDomain(params.reguri);
        }
        url = WebUtil.formatUrl(url, params);
        return url;
    }

    public doSubmitSignUpForm = async (payload: DoSubmitSignUpFormPayload) => {
        await this.DoSubmitSignUpForm(payload.form);
    }

    public doResendEmail = async () => {
        const {form} = Platform.store<StoreState>().getState().board;
        this._logger.debug("Resend email on: " + form.email);
        await this.DoSubmitSignUpForm(form);
    }

    private DoSubmitSignUpForm = async ({FullName, FirstName, LastName, email, referralCode, signUpPassword, signUpCountryCode, PhoneNumber}: SignUpFormState) => {
        const {signUpSource} = Platform.reduxState<StoreState>().board;
        const isSocialSignUp: boolean = ContactInfoSource.isSocial(signUpSource);
        const {brandId} = Platform.config<Configuration>();
        let Token: string;
        if (isSocialSignUp) {
            Token = BoardState.instance().signUpSocialToken;
            this._logger.debug("Do sign up with: " + signUpSource);
        } else {
            this._logger.debug("Do sign up with: " + email);
        }
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const meta: SignUpMetaCommon = {
            brandId,
            langCode: LanguageUtil.languageCode(),
            phoneCountryCode: signUpCountryCode,
            phoneNumber: PhoneNumber,
            verificationCode: referralCode,
        };
        const answer: [HttpReject, SignUpResult] = await Utils.to(isSocialSignUp ? this._tbSignUp.Social({
            ...meta,
            socialType: SocialMediaType.From(signUpSource),
            token: Token
        }) : this._tbSignUp.Manual({
            ...meta,
            fullName: FullName,
            firstName: FirstName,
            lastName: LastName,
            email,
            password: signUpPassword,
        }));
        Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug("Failed Sign Up");
            Platform.dispatch(SetServerError({
                fieldType: FieldType.SignUpPassword,
                error: {errorCode: ErrorCode.GeneralError}
            }));
        } else {
            Platform.dispatch(ClearServerErrors({}));
            const result: SignUpResult = answer[1];
            if (result.success) {
                Platform.environment().redirect(result.redirectUrl);
            } else {
                if (isSocialSignUp) {
                    Platform.dispatch(SetSignUpSource({signUpSource: ContactInfoSource.Manual}));
                }
                if (result.errorCode) {
                    const IsLeadExistError: boolean = result.errorCode === ErrorCode.DUPLICATE_LEAD;
                    if (IsLeadExistError) {
                        Platform.dispatch(SetServerError({
                            fieldType: FieldType.SignUpPassword,
                            error: {
                                errorCode: ErrorCode.EMAIL_ADDRESS_ALREADY_EXIST,
                                params: [Parameter.Of(TranslationParam.url, "/login")]
                            }
                        }));
                    } else {
                        Platform.dispatch(SetServerError({
                            fieldType: FieldType.SignUpPassword,
                            error: {errorCode: result.errorCode}
                        }));
                    }
                } else {
                    Platform.dispatch(SetServerError({
                        fieldType: FieldType.SignUpPassword,
                        error: {errorCode: ErrorCode.GeneralError}
                    }));
                }
            }
        }
    }

    public doSubmitLoginToken = async ({token}: DoSubmitLoginTokenPayload) => {
        this._logger.debug("Do login with token: " + token);
        const {brand} = Platform.config();
        const useToken: string = token?.replace(/\s/g, "");
        Platform.bi().track(BIEventType.LoginAttempt, {
            Brand: brand,
            LoginType: BILoginType.Token,
            Platform: BIUtil.Platform()
        });
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        setTimeout(() => {
            Platform.dispatch(HideLoader({}));
            Platform.environment().redirect("https://static-plexop.s3.amazonaws.com/temp/images/PlatformUnderConstruction.html");
        }, 2000);
    }

    public doSubmitLoginForm = async (payload: DoSubmitLoginFormPayload) => {
        const {username, loginPassword} = payload.form;
        const {UseFirebase} = Platform.config<Configuration>().products[ProductType.Board] as OnBoardProductConfig;
        this._logger.debug("Do login with: " + username + " UseFirebase: " + UseFirebase + " dpk: " + Dpk);
        const langCode: LangCode = LanguageUtil.languageCode();
        const {brand, version, brandId} = Platform.config<Configuration>();
        const ApplicationVersion: string = `${brand} ${version}`;
        let FirebaseToken: string;
        if (UseFirebase) {
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            try {
                FirebaseToken = await FirebaseAuth.Login({username, password: loginPassword, brandId});
            } catch (e) {
                this._logger.warn("Failed login with Firebase: " + e);
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.LoginPassword,
                    error: {errorCode: ErrorCode.GeneralError}
                }));
                Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
                Platform.dispatch(HideLoader({}));
                return;
            }
        }
        const loginRequest: LoginRequest = UseFirebase ? {
            BrandId: brandId,
            LanguageCode: langCode,
            FirebaseToken,
            ApplicationVersion
        } : {
            Username: username,
            Password: loginPassword,
            Token: isTnCSigningRequired ? TokenTC : null,
            BrandId: brandId,
            LanguageCode: langCode,
            isDeposit: Dpk === DpkType.Deposit,
            ApplicationVersion
        };
        await this.doLoginInternal(loginRequest, UseFirebase);
    }

    private doLoginInternal = async (loginRequest: LoginRequest, UseFirebase: boolean = false) => {
        const BiLoginData = {
            Brand: Platform.config().brand,
            LoginType: BILoginType.Regular,
            Platform: BIUtil.Platform()
        };
        Platform.bi().track(BIEventType.LoginAttempt, BiLoginData);
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, LoginResponse] = await Utils.to(XhrManager.sendToLogin(loginRequest, UseFirebase ? "FirebaseLogin": "Login"));
        Platform.dispatch(HideLoader({}));
        Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
        if (answer[0]) {
            this._logger.debug("Failed login");
            Platform.bi().track(BIEventType.LoginDecision, {
                ...BiLoginData,
                Target: BILoginTarget.LoginError,
                ServerMessage: "Server not respond"
            });
            Platform.dispatch(SetServerError({
                fieldType: FieldType.LoginPassword,
                error: {errorCode: ErrorCode.GeneralError}
            }));
        } else {
            const response: LoginResponse = answer[1];
            Platform.dispatch(ClearServerErrors({}));
            Platform.dispatch(SetChangePasswordRequired({required: response.ChangePasswordRequired}));
            if (response.ChangePasswordRequired) {
                Platform.bi().track(BIEventType.LoginDecision, {
                    ...BiLoginData,
                    Target: BILoginTarget.ChangeExpiredPassword,
                });
                Platform.dispatch(SetBoardForm({
                    form: {email: loginRequest.Username}
                }));
            }
            if (Utils.isArrayNotEmpty(response.AvailableChannels)) {
                Platform.dispatch(SetVerificationMethods({methods: response.AvailableChannels}));
            }
            if (response.TnCSigningRequired) {
                this._logger.debug("Required to accept TC");
                Platform.bi().track(BIEventType.LoginDecision, {
                    ...BiLoginData,
                    Target: BILoginTarget.TCSignRequired,
                });
                await this.doGetTermAndConditions(loginRequest.Username, loginRequest.Password, null);
            } else {
                if (response.SuccessStatus) {
                    this._logger.debug("Successful login with userId: " + response.UserId);
                    this.navigateTo(response);
                } else {
                    this._logger.debug("Failed login with username: " + loginRequest.Username);
                    const ServerMessage: string = response.LocalizedErrorMessage || response.ErrorMessage;
                    Platform.bi().track(BIEventType.LoginDecision, {
                        ...BiLoginData,
                        Target: BILoginTarget.LoginError,
                        ServerMessage
                    });
                    Platform.dispatch(SetServerError({
                        fieldType: FieldType.LoginPassword,
                        error: {
                            errorCode: response.ChangePasswordRequired ? ErrorCode.ChangePasswordRequired : ErrorCode.InvalidUsernameOrPassword,
                            errorMessage: ServerMessage
                        }
                    }));
                }
            }
        }
    }

    private doGetTermAndConditions = async (Username: string, Password: string, token: string) => {
        this._logger.debug("Fetch login TC for: " + Username);
        const request: LoginGetTermAndConditionsRequest = {
            BrandId: Platform.config<Configuration>().brandId,
            Username,
            Password,
            Token: token
        };
        const answer: [HttpReject, LoginGetTermAndConditionsResponse] = await Utils.to(XhrManager.sendToLoginTC(request, "GetTermAndConditions"));
        if (answer[0]) {
            this._logger.debug("Failed fetch TC");
            Platform.dispatch(SetServerError({
                fieldType: FieldType.LoginPassword,
                error: {errorCode: ErrorCode.GeneralError}
            }));
        } else {
            const {Id, SummaryURL} = answer[1];
            if (Utils.greaterThen0(Id) && Utils.isNotEmpty(SummaryURL)) {
                Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
                Platform.dispatch(SetTCLoginAgreement({
                    tcAgreement: {
                        username: Username,
                        password: Password,
                        id: Id,
                        contentUrl: SummaryURL
                    }
                }));
                Platform.dispatch(SetModal({
                    modalType: ModalType.LoginTC,
                    visible: true
                }));
            } else {
                this._logger.debug("Can't show Login TC modal. URL or Id missing in response");
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.LoginPassword,
                    error: {errorCode: ErrorCode.GeneralError}
                }));
            }
        }
    }

    public doLoginNotifyUserAgreement = async () => {
        const {id, username, password} = Platform.reduxState<StoreState>().board.tcAgreement;
        this._logger.debug("Notify login agreed with TC for: " + username);
        const request: LoginNotifyAgreeTCRequest = {
            Id: id,
            BrandId: Platform.config<Configuration>().brandId,
            Username: username,
            Password: password,
            Token: isTnCSigningRequired ? TokenTC : null
        };
        const answer: [HttpReject, LoginNotifyAgreeTCResponse] = await Utils.to(XhrManager.sendToLoginTC(request, "NotifyUserAgreement"));
        if (answer[0]) {
            this._logger.debug("Failed login agreed with TC");
            Platform.dispatch(SetServerError({
                fieldType: FieldType.LoginPassword,
                error: {errorCode: ErrorCode.GeneralError}
            }));
        } else {
            Platform.dispatch(DoSubmitLoginForm({
                form: {
                    username,
                    loginPassword: password
                }
            }));
        }
    }

    private navigateTo = ({UserId, RedirectURL, RedirectURLOldPlatform, RedirectURLNewPlatform, RegistrationDate, FormMethod, Token, CesLeadToken, IsRealAccount}: TokenResponse): void => {
        const {langCode} = Platform.reduxState().translation;
        const {brand} = Platform.config();
        const isDeposit: boolean = Dpk === DpkType.Deposit;
        Platform.bi().set({
            UserId,
            realAccount: IsRealAccount
        });
        const submit = (url: string, method: RedirectFormMethod) => {
            Platform.bi().track(BIEventType.LoginDecision, {
                Brand: brand,
                LoginType: BILoginType.Regular,
                Platform: BIUtil.Platform(),
                Target: isDeposit ? BILoginTarget.Deposit : BILoginTarget.Platform,
            });
            const {LoginUrl, PlatformUrl} = Platform.config<Configuration>();
            let parameters: { [key: string]: string } = {
                "token": Token,
                "language": langCode,
                CesLeadToken
            };
            if (LoginUrl) {
                parameters.loginUrl = encodeURIComponent(LoginUrl);
            }
            const targetUrl: string = PlatformUrl || ConfigUtil.UseAddressBarDomain(url);
            if (method === RedirectFormMethod.POST) {
                parameters.dpk = QueryString;
                SubmitForm.post(targetUrl, parameters);
            } else {
                if (DpkParam) {
                    parameters = {...parameters, ...WebUtil.getParamsToObject(QueryStringRaw)};
                }
                Platform.environment().redirect(WebUtil.addGetParams(targetUrl, parameters));
            }
        };
        const {migrationDialogType, forkSignUpThreshold} = Platform.reduxState<StoreState>().board;
        if (migrationDialogType === MigrationDialogType.Fork && Dpk !== DpkType.Deposit) {
            if (Utils.isEmpty(forkSignUpThreshold) || !Utils.greaterThen0(RegistrationDate) || DateTimeFormat.parseDate(forkSignUpThreshold).isAfter(DateTimeFormat.Of(RegistrationDate))) {
                Platform.dispatch(SetModal({
                    modalType: ModalType.SoftLaunch,
                    visible: true,
                    parameters: [
                        Parameter.Of("Profit", () => {
                            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                            submit(RedirectURLOldPlatform, RedirectFormMethod.POST);
                        }),
                        Parameter.Of("XCite", () => {
                            const {brand, version} = Platform.config();
                            const request: RegisterActivityRequest = {
                                ActivityType: ActivityTypeInfo.CustomActivity,
                                Details: `${brand} ${version}`,
                                Comments: `${brand} ${version}`
                            };
                            Xhr.sendTo(ServerType.Compliance, request, "ComplianceWebsite/ComplianceFormService.svc/json/RegisterActivity", Token).catch(() => {
                                this._logger.debug("Failed register activity: " + ActivityTypeInfo.CustomActivity);
                            });
                            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                            submit(RedirectURLNewPlatform, RedirectFormMethod.GET);
                        })
                    ]
                }));
            } else {
                submit(RedirectURLNewPlatform, RedirectFormMethod.GET);
            }
        } else {
            submit(RedirectURL, FormMethod);
        }
    }

    public doSubmitForgotPasswordForm = async ({form}: DoSubmitForgotPasswordFormPayload) => {
        const {email} = form;
        this._logger.debug("Perform forgot password for: " + email);
        const brandId: number = Platform.config<Configuration>().brandId;
        const request: ForgotPasswordRequest = {UserName: email, BrandId: brandId};
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, ForgotPasswordResponse] = await Utils.to(XhrManager.sendToLogin(request, "ForgetPassword"));
        Platform.dispatch(HideLoader({}));
        Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
        if (answer[0]) {
            this._logger.debug("Failed forgot password");
            Platform.dispatch(SetResetLinkSendToEmail({sent: false}));
            Platform.dispatch(ShowPopup({popup: {
                    icon: {type: PopupIconType.ERROR},
                    title: {trKey: TranslationKey.oops},
                    message: {trKey: TranslationKey.serverErrorGeneral},
                    showClose: true,
                    actions: [{type: PopupActionType.OK}]
                }}));
        } else {
            const {SuccessStatus, SentChannelType, AvailableChannels} = answer[1];
            this._logger.debug(`Forgot password verification code. Sent channel ${SentChannelType}. Status ${SuccessStatus}`);
            if (!SuccessStatus) {
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.Email,
                    error: {
                        errorCode: ErrorCode.EmailAddressNotFound
                    }
                }));
            } else {
                this._logger.debug("Successful forgot password");
                if (SentChannelType) {
                    Platform.dispatch(SetResetLinkSendToEmail({sent: true}));
                    Platform.dispatch(SetVerificationMethods({methods: AvailableChannels}));
                    Platform.dispatch(ClearServerErrors({}));
                    Platform.dispatch(NavigateTo({route: PageType.Verification}));
                } else {
                    Platform.dispatch(ShowPopup({popup: {
                            icon: {type: PopupIconType.ERROR},
                            title: {trKey: TranslationKey.oops},
                            message: {trKey: TranslationKey.serverErrorGeneral},
                            showClose: true,
                            actions: [{type: PopupActionType.OK}]
                        }}));
                }
            }
        }
    }

    public onGetResetPasswordVerificationCode = async ({method, ResendVerificationCode}: VerificationMethodPayload) => {
        const {email} = Platform.reduxState<StoreState>().board.form;
        this._logger.debug("Change verification method to: " + method);
        const brandId: number = Platform.config<Configuration>().brandId;
        const request: GetResetPasswordVerificationCodeRequest = {
            email,
            brandId,
            SendChannelType: method,
            ResendVerificationCode,
            Source: "ForgetPassword"
        };
        Platform.dispatch(SetVerificationCodeIssued({
            issued: false
        }));
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, GetResetPasswordVerificationCodeResponse] = await Utils.to(XhrManager.sendToLogin(request, "GetResetPasswordVerificationCode"));
        Platform.dispatch(HideLoader({}));
        if (answer[0] || !answer[1]?.Success) {
            this._logger.debug("Failed change verification method");
            Platform.dispatch(ShowPopup({popup: {
                    icon: {type: PopupIconType.ERROR},
                    title: {trKey: TranslationKey.oops},
                    message: {trKey: TranslationKey.serverErrorGeneral},
                    showClose: true,
                    actions: [{type: PopupActionType.OK}]
                }}));
        } else {
            const {AvailableChannels, IsLimitReached} = answer[1];
            Platform.dispatch(SetVerificationMethod({method}));
            Platform.dispatch(SetVerificationMethods({methods: AvailableChannels}));
            Platform.dispatch(SetVerificationCodeIssued({
                issued: true
            }));
            Platform.dispatch(SetVerificationIsLimitReached({IsLimitReached}));
        }
    }

    public doVerifyResetPassword = async ({form}: DoVerifyResetPasswordPayload) => {
        const {code} = form;
        const {email} = Platform.reduxState<StoreState>().board.form;
        this._logger.debug("Ask verify code for: ", email, " code: ", code);
        const request: VerifyResetPasswordCodeRequest = {
            email,
            code,
            brandId: Platform.config<Configuration>().brandId,
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, VerifyResetPasswordCodeResponse] = await Utils.to(XhrManager.sendToLogin(request, "VerifyResetPasswordCode"));
        Platform.dispatch(HideLoader({}));
        Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
        if (answer[0]) {
            Platform.dispatch(ShowPopup({popup: {
                    icon: {type: PopupIconType.ERROR},
                    title: {trKey: TranslationKey.oops},
                    message: {trKey: TranslationKey.serverErrorGeneral},
                    showClose: true,
                    actions: [{type: PopupActionType.OK}]
                }}));
        } else {
            const {Success, LocalizeMessage} = answer[1];
            if (!Success) {
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.VerificationCode,
                    error: {
                        errorCode: ErrorCode.GeneralError,
                        errorMessage: LocalizeMessage
                    }
                }));
            } else {
                Platform.dispatch(NavigateTo({route: PageType.ResetPassword}));
            }
        }
    }

    public doSubmitResetPasswordForm = async ({form}: DoSubmitResetPasswordFormPayload) => {
        const {newPassword, confirmPassword} = form;
        if (newPassword === confirmPassword) {
            const {brand, version} = Platform.config();
            const {email, code} = Platform.reduxState<StoreState>().board.form;
            this._logger.debug(`Perform reset password. Email ${email}, code ${code}`);
            const request: ResetPasswordAndLoginRequest = {
                email,
                code,
                newPassword,
                brandId: Platform.config<Configuration>().brandId,
                ApplicationVersion: `${brand} ${version}`
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, LoginResponse] = await Utils.to(XhrManager.sendToLogin(request, "ResetPasswordAndLogin"));
            Platform.dispatch(HideLoader({}));
            Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
            if (answer[0]) {
                this._logger.debug("Failed reset password");
                Platform.dispatch(SetPasswordReset({reset: false}));
                Platform.dispatch(ShowPopup({popup: {
                        icon: {type: PopupIconType.ERROR},
                        title: {trKey: TranslationKey.oops},
                        message: {trKey: TranslationKey.serverErrorGeneral},
                        showClose: true,
                        actions: [{type: PopupActionType.OK}]
                    }}));
            } else {
                const response: LoginResponse = answer[1];
                if (response.SuccessStatus) {
                    this._logger.debug("Successful login after reset password with userId: " + response.UserId);
                    Platform.dispatch(SetPasswordReset({reset: true}));
                    Platform.dispatch(ClearServerErrors({}));
                    this.navigateTo(response);
                } else {
                    Platform.dispatch(SetPasswordReset({reset: false}));
                    Platform.dispatch(SetServerError({
                        fieldType: FieldType.ConfirmPassword,
                        error: {errorMessage: response.LocalizedErrorMessage || response.ErrorMessage}
                    }));
                }
            }
        } else {
            this._logger.debug("Passwords do not match");
            Platform.dispatch(SetPasswordReset({reset: false}));
            Platform.dispatch(SetServerError({
                fieldType: FieldType.ConfirmPassword,
                error: {errorCode: ErrorCode.PasswordsNotMatch}
            }));
        }
    }

    public doSubmitChangePasswordForm = async (payload: DoSubmitChangePasswordFormPayload) => {
        const {username, loginPassword, newPassword, confirmPassword} = payload.form;
        if (newPassword === confirmPassword) {
            this._logger.debug("Perform change password. Dpk: " + Dpk);
            const langCode: LangCode = LanguageUtil.languageCode();
            const request: ChangeExpiredPasswordRequest = {
                BrandId: Platform.config<Configuration>().brandId,
                LanguageCode: langCode,
                OldPassword: loginPassword,
                NewPassword: newPassword,
                Username: username,
                isDeposit: Dpk === DpkType.Deposit
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, ChangeExpiredPasswordResponse] = await Utils.to(XhrManager.sendToLogin(request, "ChangeExpiredPassword"));
            Platform.dispatch(HideLoader({}));
            Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
            if (answer[0]) {
                this._logger.debug("Failed change password");
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.ConfirmPassword,
                    error: {errorMessage: XhrManager.fetchRejectReason(answer), errorCode: ErrorCode.GeneralError}
                }));
            } else {
                const response: ChangeExpiredPasswordResponse = answer[1];
                if (response.SuccessStatus) {
                    this._logger.debug("Successful changed password for user: " + username);
                    Platform.dispatch(ClearServerErrors({}));
                    this.navigateTo(response);
                } else {
                    this._logger.debug("Failed change password with username: " + username);
                    Platform.dispatch(SetServerError({
                        fieldType: FieldType.ConfirmPassword,
                        error: {
                            errorCode: ErrorCode.InvalidUsernameOrPassword,
                            errorMessage: response.LocalizedErrorMessage || response.ErrorMessage
                        }
                    }));
                }
            }
        } else {
            this._logger.debug("Passwords do not match");
            Platform.dispatch(SetServerError({
                fieldType: FieldType.ConfirmPassword,
                error: {errorCode: ErrorCode.PasswordsNotMatch}
            }));
        }
    }
}
